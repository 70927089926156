'use strict'

import { mapActions, mapState } from 'pinia'

// import EventBus from '../event-bus/EventBus'
import { useAdaStore } from '../stores/ada'
import { usePageStore } from '../stores/page'
import deviceDetection from './device-detection'
import adaMoltenBundle from './ada-molten-bundle'

export default {
  data () {
    return {
      screenTypeMinWidth: {
        largeDesktop: 1580,
        mediumDesktop: 1000,
        smallDesktop: 768,
        mobile: 0
      },
      orderedSlotLists: {
        article: {
          mobile: ['mobile_1', 'mobile_2', 'outstream', 'mobile_3', 'mobile_4', 'mobile_5'],
          smallDesktop: ['rectangle_1', 'outstream', 'rectangle_2', 'rectangle_3', 'rectangle_4', 'rectangle_5'],
          mediumDesktop: ['outstream'],
          largeDesktop: ['outstream']
        },
        structureterm: {
          mobile: ['mobile_1', 'mobile_2', 'ncb_top', 'mobile_3', 'mobile_4', 'mobile_5'],
          smallDesktop: ['rectangle_1', 'ncb_top', 'rectangle_2', 'rectangle_3', 'rectangle_4', 'rectangle_5'],
          // no superbanner above this line, the viewport is too small
          mediumDesktop: ['rectangle_1', 'ncb_top', 'superbanner_2', 'rectangle_2', 'superbanner_3', 'rectangle_3', 'rectangle_4', 'rectangle_5', 'superbanner_4', 'superbanner_5'],
          largeDesktop: ['rectangle_1', 'ncb_top', 'superbanner_2', 'rectangle_2', 'superbanner_3', 'rectangle_3', 'rectangle_4', 'rectangle_5', 'superbanner_4', 'superbanner_5']
        },
        asyncContent: {
          mobile: ['mobile_1', 'mobile_2', 'mobile_3', 'mobile_4', 'mobile_5'],
          smallDesktop: ['rectangle_1', 'rectangle_2', 'rectangle_3', 'rectangle_4', 'rectangle_5'],
          mediumDesktop: [],
          largeDesktop: []
        }
      },
      clientConfig: {
        vw: null,
        vh: null,
        screenType: null,
        startOffsetTop: null,
        slotDistanceOffset: null,
        slotList: []
      },
      oneTimeRegistrationAdSlots: []
    }
  },
  mixins: [deviceDetection, adaMoltenBundle],
  computed: {
    ...mapState(useAdaStore, ['getAdSlotMarker', 'dynamicAdSlotsEnabled', 'isAsyncContentMode', 'staticMobile1OnHome']),
    ...mapState(usePageStore, ['isArticleOrArticleVersion']),
    pageTypeLogic () {
      return this.isAsyncContentMode ? 'asyncContent' : this.isArticleOrArticleVersion || this.metaData?.behaveLikeArticle ? 'article' : 'structureterm'
    }
  },
  mounted () {
    // // disabled asyncContent ad loading solution for further development
    // EventBus.$on('ad-loader:allocation', this.handleEventAllocation)
  },
  methods: {
    // handleEventAllocation () {
    //   this.$nextTick(() => {
    //     this.dynamicAdSlotAllocation()
    //   })
    // },
    ...mapActions(useAdaStore, ['setActivatedAdSlotMarker']),
    initAdaDynamicSlotLoader () {
      this.setClientConfig()
      // We want to initialize the Molten Bundle as quickly as possible,
      // so we initially only register the static (fixed positioned) ad slots.
      this.checkStaticSlotsToRegister()
      // and the dynamic slots will be registered afterwards
      this.initMoltenBundle()
      window.bxMb.clientConfig = this.clientConfig

      this.$nextTick(() => {
        if (!this.isAsyncContentMode) {
          this.dynamicAdSlotAllocation()
        }
      })
    },

    /**
     * Setting up the configuration for rendering dynamic slots
     * based on the viewport dimensions, screen type, and other conditions.
     * The configuration is stored in the clientConfig object.
     */
    setClientConfig () {
      // get the viewport dimensions
      const vw = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
      const vh = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight

      // we do not want to start placing dynamic slots above the fold
      const startOffsetTop = vh

      // define default distance between ad slots
      const slotDistanceOffset = 600

      // get the current screenType based on the viewport width
      let screenType = null
      Object.keys(this.screenTypeMinWidth).forEach(el => {
        if (!screenType && vw >= this.screenTypeMinWidth[el]) {
          screenType = el
        }
      })

      // get the defined ad slot list based on the pageTypeLogic and screenType
      let slotList = this.orderedSlotLists[this.pageTypeLogic][screenType]
      const exclusionList = []

      // add native crossbar to exclusionList if native teaser are disallowed
      if (this.metaData?.disallowNativeTeasers || this.metaData?.advertorial) {
        exclusionList.push('ncb_top')
      }

      // add outstream to exclusionList if disallowed
      if (this.metaData?.disallowPosterAds) {
        exclusionList.push('outstream')
      }

      // remove mobile_1 from dynamic list while placing it statically on home
      if (this.staticMobile1OnHome) {
        exclusionList.push('mobile_1')
      }

      // filter out excluded slots
      if (exclusionList.length) {
        slotList = slotList.filter(item => !exclusionList.includes(item))
      }

      // set default config
      this.clientConfig = { vw, vh, screenType, startOffsetTop, slotDistanceOffset, slotList, exclusionList }

      // check config modifications based on screenType and pageType
      const configModsMethod = `${screenType}ConfigMods`
      if (typeof this[configModsMethod] === 'function') {
        this[configModsMethod]()
      }
    },

    /**
     * These methods are called from setClientConfig based on the identified screen type
     * (large desktop, medium desktop, small desktop, or mobile).
     * They modify the clientConfig object's properties
     * based on the page type logic and viewport dimensions.
     */
    largeDesktopConfigMods () {
      this.combinedMediumAndLargeDesktopConfigMods()
    },
    mediumDesktopConfigMods () {
      this.combinedMediumAndLargeDesktopConfigMods()
    },
    combinedMediumAndLargeDesktopConfigMods () {
      if (this.pageTypeLogic === 'article') {
        // in this case we just want to inject the outstream_div at the lower end of the article
        const asideWrapperNode = document.querySelector('.bx-aside-wrapper')
        let leadOffset = 0
        // lets check, if the lead paragraph is separated and placed above the sidebar
        const separateLeadParagraphNode = document.querySelector('main.asset-container > .bx-paragraph-list')
        // if there is a separated lead paragraph, we need to add its height to the offset
        if (separateLeadParagraphNode) {
          leadOffset = separateLeadParagraphNode.clientHeight
        }
        // now we can calculate the correct startOffsetTop taking into account the lead offset and the aside wrapper height
        this.clientConfig.startOffsetTop = asideWrapperNode.clientHeight * 0.75 + leadOffset
      } else {
        this.clientConfig.startOffsetTop = this.clientConfig.vh - 400
      }
    },
    smallDesktopConfigMods () {
      this.combinedMobileAndSmallDesktopConfigMods()
    },
    mobileConfigMods () {
      this.combinedMobileAndSmallDesktopConfigMods()
    },
    combinedMobileAndSmallDesktopConfigMods () {
      this.clientConfig.slotDistanceOffset = this.clientConfig.vh + 50
      this.clientConfig.startOffsetTop = this.clientConfig.vh / 2
    },

    /**
     * Dynamically allocating ad slots based on the configured slotDistanceOffset and
     * the available slots in slotList. It iterates through a sorted list of marker offsets,
     * calculates the distance between each marker and the reference offset,
     * and assigns a slot from slotList to the marker if the distance is greater than
     * or equal to slotDistanceOffset and there are available slots.
     */
    dynamicAdSlotAllocation () {
      // set initial referenceOffset to start calculating distances
      let referenceOffset = this.clientConfig.startOffsetTop
      // fetch registered adSlotMarker
      const adSlotMarker = { ...this.getAdSlotMarker }
      // object to save activated markers
      const activatedMarker = {}
      // sort the adSlotMarker by keys (offset)
      const sortedMarker = Object.keys(adSlotMarker).sort((a, b) => a - b)

      // loop through sorted markers
      sortedMarker.forEach((markerOffset) => {
        // get the marker object
        const marker = adSlotMarker[markerOffset]
        // calculate the distance between the current marker offset and the reference offset
        const distance = markerOffset - referenceOffset
        // get the count of activated markers
        const activatedMarkerCount = Object.keys(activatedMarker).length

        // check if the distance is greater than the dynamicAdSlotDistance
        // and if the activatedMarkerCount is less than the available slots
        if (
          distance >= this.clientConfig.slotDistanceOffset &&
          activatedMarkerCount < this.clientConfig.slotList.length &&
          this.isSafePlacementForRectangleAside(adSlotMarker[markerOffset].parentOffsetTop, this.clientConfig.slotList[activatedMarkerCount])
        ) {
          // set the renderSlot for the current marker
          marker.renderSlot = this.clientConfig.slotList[activatedMarkerCount]
          // set the screenType for the current marker
          marker.screenType = this.clientConfig.screenType
          // add the marker to the activatedMarker object
          activatedMarker[markerOffset] = marker
          // console.log('##### loop Marker - HIT ', marker.renderSlot, referenceOffset, markerOffset, distance)
          // set the referenceOffset to the current marker offset
          referenceOffset = markerOffset
          // register dynamic slot, while it's not an outstream slot
          if (marker.renderSlot !== 'outstream') {
            const pushSlot = marker.renderSlot === 'ncb_top'
              ? ['teaser_11', 'teaser_12', 'teaser_13']
              : [marker.renderSlot]
            this.registerAdSlotOnce(pushSlot)
          }
        }
      })

      // propagate the activated markers to the store for initialization of the ad slots
      this.setActivatedAdSlotMarker(activatedMarker)
    },

    isSafePlacementForRectangleAside (parentOffsetTop, slotToRender) {
      // if we are on medium or large Desktop and the slot to render is a rectangle, it's not safe to place it above the fold.
      // While the parentOffsetTop is smaller than the viewport height, we would generate a layout shift
      return !(['mediumDesktop', 'largeDesktop'].includes(this.clientConfig.screenType) && slotToRender.includes('rectangle') && parentOffsetTop <= this.clientConfig.vh)
    },

    /**
     * Registering static ad slots based on the viewport width and the metaData configuration.
     */
    checkStaticSlotsToRegister () {
      if (this.clientConfig.vw >= 768) {
        this.registerSlots.push('skyscraper_1')
      } else {
        this.registerSlots.push('mobile_10')
        // static mobile 1 is only placed on home otherwise dynamic
        if (this.staticMobile1OnHome) {
          this.registerSlots.push('mobile_1')
        }
      }

      if (this.clientConfig.vw >= 888) {
        this.registerSlots.push('wallpaper_1', 'superbanner_1')
      }

      if (this.clientConfig.vw >= 990) {
        this.registerSlots.push('dmofooter_1')
      }

      if (!this.metaData?.disallowNativeTeasers && !this.metaData?.advertorial) {
        // register teaser slots with fix position at once.
        // ---------------------
        // On article pages, the first three slots (teaser_11 to 13) have a fixed position below the article paragraphs and can be registered here initially.
        // On structureterm pages, the first three slots (teaser_11 to 13) are positioned dynamic, within the page content and will be registered separately through registerAdSlotOnce.
        // The computed value of enableNcbInDynamicMode is based on the pageTypeLogic and the dynamicAdSlotsEnabled configuration and used to determine the slots to register.
        // So, only if we are in enableNcbInDynamicMode, we register the first three teaser slots at this point.
        const startingIndex = this.enableNcbInDynamicMode ? 1 : 4
        // 11 to 13 are for AdaNativeCrossbar top (3)
        // 14 to 19 are for AdaNativeCrossbar bottom (6)
        for (let i = startingIndex; i <= 9; i++) {
          this.registerSlots.push(`teaser_${10 + i}`)
        }
      }
    },

    /**
     * Register the ad slot for the molten bundle only once
     *
     * @param {string} adSlot
     */
    registerAdSlotOnce (adSlots) {
      if (!this.blockAds && adSlots) {
        // check if molten bundle is already loaded, if not wait for it
        if (window?.MoltenBundle?.cmd) {
          adSlots.forEach((adSlot) => {
            if (!this.oneTimeRegistrationAdSlots.includes(adSlot)) {
              this.oneTimeRegistrationAdSlots.push(adSlot)
              window.MoltenBundle.cmd.push(() => {
                window.MoltenBundle.push(adSlot)
              })
            }
          })
        } else {
          setTimeout(() => {
            this.registerAdSlotOnce(adSlots)
          }, 100)
        }
      }
    }
  }
}
