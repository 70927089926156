import { mapActions, mapState } from 'pinia'

import { useConfigStore } from '../stores/config'
import { usePageStore } from '../stores/page'
import { useAdaStore } from '../stores/ada'
import { useSourcepointStore } from '../stores/sourcepoint'
import { useSessionStore } from '../stores/session'

export default {
  props: {
    brand: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    },
    authorization: {
      type: String,
      default: null
    },
    consentConfig: {
      type: Object,
      default: () => {}
    },
    adSizePrediction: {
      type: Object,
      default: () => {}
    },
    adDefendScript: {
      type: String,
      default: ''
    },
    config: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState(useConfigStore, ['rsConfig'])
  },
  methods: {
    ...mapActions(useConfigStore, ['setRsConfig']),
    ...mapActions(usePageStore, ['fetchPage', 'appMounted']),
    ...mapActions(useSourcepointStore, ['setConsentConfig']),
    ...mapActions(useAdaStore, ['setAdSizePrediction', 'setAdDefendScript']),
    ...mapActions(useSessionStore, ['setToken'])
  },
  async mounted () {
    await this.appMounted()
  },
  async serverPrefetch () {
    const { url, brand, consentConfig, adSizePrediction, adDefendScript, config, authorization } = this

    this.setConsentConfig(consentConfig)
    this.setRsConfig(config)
    this.setAdSizePrediction(adSizePrediction)
    this.setAdDefendScript(adDefendScript)

    const authz = authorization || this.$ssrContext?.Authorization

    if (authz) {
      this.setToken(authz)
    }

    return this.fetchPage({ url, brand, rsConfig: this.rsConfig })
  }
}
