<template>
  <a v-bind="productTagSpecialAttributes(product)"
     :id="id"
     :key="productIndex"
     :data-product-type="product.type"
     :data-product-id="product.id"
     :class="productStyleClasses">
    <div v-if="isDealsProduct"
         class="bx-product__deal-info">
      <span :data-dealendtime="product.dealEndTime"
            class="bx-dealinfo__endtime" />
      <span v-if="product.listPrice > 0 && product.saving"
            class="bx-dealinfo__saving">
        - {{ product.saving }}%
      </span>
    </div>
    <div class="bx-product__wrapper">
      <div v-if="!isDealsProduct && reserveSpaceForLabel"
           :class="{
             [`bx-product__label`]: true,
             [`bx-product__label--no`]: !product.label
           }">
        <div v-if="product.label"
             data-tc="product-label">
          <span>{{ product.label }}</span>
        </div>
        <span v-if="showRanking"
              class="bx-product__rank">
          {{ productIndex + 1 }}
        </span>
      </div>
      <div v-if="!isDealsProduct && reserveSpaceForLabelShoppingDays"
           :class="{
             [`bx-product__label`]: true,
             [`bx-product__label--no`]: !product.shoppingDaysLabel,
             [`bx-product__label--shopping-days`]: true
           }"
           data-tc="product-shopping-days-label">
        <div>
          <span>{{ product.shoppingDaysLabel }}</span>
        </div>
      </div>
      <div class="bx-product__content">
        <figure v-if="product.image"
                class="bx-product__image"
                data-tc="product-image">
          <img :src="product.image"
               :alt="product.title">
        </figure>
        <div class="bx-product__inner">
          <div class="bx-product__main">
            <span class="bx-product__title"
                  data-tc="product-title">
              {{ product.title }}
            </span>
            <div v-if="product.showPrime"
                 class="bx-product__prime">
              <img :src="primeLogo"
                   alt="Amazon Prime">
            </div>
          </div>
          <div class="bx-product__footer">
            <div v-if="!product.outOfStock"
                 class="bx-product__pricing"
                 data-tc="product-pricing">
              <span v-if="product.sellingPrice"
                    class="bx-product__sp"
                    data-tc="product-sp">
                {{ formatCurrencyString(product.sellingPrice) }}
              </span>
              <div v-if="product.listPrice"
                   class="bx-product__pricing--inner">
                <span class="bx-product__lp"
                      data-tc="product-lp">
                  {{ formatCurrencyString(product.listPrice) }}
                </span>
                <span v-if="product.saving && product.saving >= 10 && !isDealsProduct"
                      class="bx-product__saving"
                      data-tc="product-saving">
                  - {{ product.saving }}%
                </span>
              </div>
            </div>
            <span class="bx-product__roofline"
                  data-tc="product-roofline">{{ product.roofline }}</span>
            <div :class="['bx-product__button', {
                   'bx-product__button--oos': product.outOfStock,
                   'bx-product__button--space-below': noExtendedInformationBelow
                 }]"
                 data-tc="product-button">
              <span>{{ product.button }}</span>
              <svg v-if="isDealsProduct"
                   class="icon icon-arrow_pager-next">
                <use href="/images/icon_sprite.svg#arrow_pager-next" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="hasProductStructuredData(product)"
         :key="`structured-data-${productIndex}`"
         v-html="getProductStructuredData(product)" />
  </a>
</template>

<script>
import { mapState } from 'pinia'

import { useConfigStore } from '../../../stores/config'
import { usePageStore } from '../../../stores/page'
import imgSrcSet from '../../../mixins/img-src-set'
import productGroup from '../../../mixins/product-group'

export default {
  mixins: [
    productGroup,
    imgSrcSet
  ],
  props: {
    // Used by mixin productGroup TODO: refactor this
    // eslint-disable-next-line vue/no-unused-properties
    productGroupType: {
      type: String,
      default: () => 'single'
    },
    product: {
      type: Object,
      default: () => {}
    },
    productIndex: {
      type: Number,
      default: () => 0
    },
    isDealsProduct: {
      type: Boolean,
      default: () => false
    },
    id: {
      type: [String, Boolean],
      default: () => false
    },
    isPreview: {
      type: Boolean,
      default: false
    },
    showRanking: {
      type: Boolean,
      default: () => false
    },
    isContentCommerce: {
      type: Boolean,
      default: () => {}
    },
    noExtendedInformationBelow: {
      type: Boolean,
      default: () => false
    },
    reserveSpaceForLabelShoppingDays: {
      type: Boolean,
      default: () => false
    },
    reserveSpaceForLabel: {
      type: Boolean,
      default: () => true
    },
    // Used by mixin productGroup (we can't use store prop because of GUI preview)
    // eslint-disable-next-line vue/no-unused-properties
    brand: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      // Used by mixin productGroup TODO: refactor this
      // eslint-disable-next-line vue/no-unused-properties
      prefix: 'bx'
    }
  },
  computed: {
    // Some of these store props are also or only used by mixins
    ...mapState(useConfigStore, ['rsConfig']),
    ...mapState(usePageStore, ['pageData']),
    primeLogo () {
      return (this.isPreview ? this.rsConfig.socialSettings.shareBaseUrl : '') + '/images/prime.png'
    }
  },
  methods: {
    // validates and extracts author data, returns empty array if all failed
    getAuthors (author) {
      return (Array.isArray(author) ? author : [author])
        .filter(a => a?.titleLong && a?.url) // validation
        .map(a => ({ // extraction
          name: a.titleLong,
          url: this.pageData.pageMeta.baseUrl + a.url
        }))
    },
    createAuthorsStructuredData () {
      const authors = [
        ...this.getAuthors(this.pageData?.body?.author),
        ...this.getAuthors(this.pageData?.body?.additionalAuthors)
      ].map(({ name, url }) => ({ '@type': 'Person', name, url })) // create stuctured data

      if (authors.length > 1) { // if there are more than one authors, we create an array stucture
        return authors
      }

      if (authors.length === 1) { // if there's only one author, we create a single object
        return authors[0]
      }

      // otherwise, we "return" undefined
    },
    hasProductStructuredData (product) {
      return product && this.isContentCommerce && !this.isPreview
    },
    getProductStructuredData (product) {
      // pros
      const pros = product.prosAndCons?.pros?.map(pro => {
        return {
          '@type': 'PropertyValue',
          name: 'Pros',
          value: pro?.replaceAll('<p>', '').replaceAll('</p>', '').trim()
        }
      }) || []

      // cons
      const cons = product.prosAndCons?.cons?.map(con => {
        return {
          '@type': 'PropertyValue',
          name: 'Cons',
          value: con?.replaceAll('<p>', '').replaceAll('</p>', '').trim()
        }
      }) || []

      // technical data
      const technicalData = product.technicalData?.map(({ key: name, value }) => ({
        '@type': 'PropertyValue',
        name,
        value
      })) || []

      const json = {
        '@context': 'https://schema.org/',
        '@type': 'Product',
        name: product.title,
        image: [product.image],
        description: product.description,
        offers: {
          '@type': 'Offer',
          url: product.link?.url,
          priceCurrency: 'EUR',
          availability: 'InStock'
        },
        additionalProperty: [...pros, ...cons, ...technicalData]
      }
      if (product.sellingPrice) {
        json.offers.price = (product.sellingPrice / 100).toFixed(2)
      }
      if (product.editorialStarRating) {
        json.review = {
          '@type': 'Review',
          reviewRating: {
            '@type': 'Rating',
            ratingValue: `${product.editorialStarRating}`,
            bestRating: '5'
          }
        }
      }

      // note only available in the RS - irrelevant for the preview
      const authorsStructuredData = this.createAuthorsStructuredData()
      if (authorsStructuredData && json.review) {
        json.review.author = authorsStructuredData
      }

      // eslint-disable-next-line no-useless-escape
      return `<script type="application/ld+json">${JSON.stringify(json)}<\/script>`
    },
    productTagSpecialAttributes (product) {
      return {
        href: product.link.url,
        target: product.link.target,
        rel: product.link.rel
      }
    }
  }
}
</script>
